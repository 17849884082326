import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import "./styles/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import { faqData } from "./faq";
import Accordion from "./components/Accordion";
import { Swiper, SwiperSlide } from "swiper/react";

// IMAGES
import i1 from "./assets/i1.png";
import i2 from "./assets/i2.png";
import i3 from "./assets/i3.png";
import i4 from "./assets/i4.png";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { EffectCoverflow } from "swiper";

// install Swiper modules
SwiperCore.use([EffectCoverflow]);

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	font-family: "poppins-bold", sans-serif !important;
	padding: 10px;
	border-radius: 50px;
	background-color: var(--secondary);
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
	box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const StyledRoundButton = styled.button`
	font-family: "poppins-bold", sans-serif !important;
	padding: 10px;
	border-radius: 5px;
	border-width: 1px;
	border-color: white;
	border-style: solid;
	background: linear-gradient(175.07deg, #cf027e -9.38%, #510777 129.5%);
	padding: 10px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	font-family: "poppins-bold", sans-serif !important;
	width: 100%;
`;

export const StyledLogo = styled.img`
	font-family: "poppins-bold", sans-serif !important;
	width: 80%;
	object-fit: contain;
	transition: width 0.5s;
	transition: height 0.5s;
`;
export const StyledLogo2 = styled.img`
	font-family: "poppins-bold", sans-serif !important;
	width: 150%;
	object-fit: contain;
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	font-family: "poppins-bold", sans-serif !important;
	box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
	border: 4px dashed var(--secondary);
	background-color: var(--accent);
	border-radius: 100%;
	width: 200px;
	@media (min-width: 900px) {
		width: 250px;
	}
	@media (min-width: 1000px) {
		width: 300px;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;

function App() {
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(`Click buy to mint your NFT`);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		SCAN_LINK: "",
		NETWORK: {
			NAME: "",
			SYMBOL: "",
			ID: 0,
		},
		NFT_NAME: "",
		SYMBOL: "",
		MAX_SUPPLY: 1,
		WEI_COST: 0,
		DISPLAY_COST: 0,
		GAS_LIMIT: 0,
		MARKETPLACE: "",
		MARKETPLACE_LINK: "",
		SHOW_BACKGROUND: false,
	});

	const sliderData = [i1, i2, i3, i4];

	const claimNFTs = () => {
		let cost = CONFIG.WEI_COST;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(BigInt(cost * mintAmount));
		let totalGasLimit = String(BigInt(gasLimit * mintAmount));
		console.log("Cost: ", totalCostWei);
		console.log("Gas limit: ", totalGasLimit);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once("error", (err) => {
				console.log(err);
				setFeedback("Sorry, something went wrong please try again later");
				setClaimingNft(false);
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(
					`WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
				);
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > 20) {
			newMintAmount = 20;
		}
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<>
			<s.Screen>
				<s.ContainerUpp
					style={{ padding: 24, height: "100vh", backgroundColor: "var(--primary-text)" }}
					image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpg" : null}
				>
					<div className="back"><a href="https://wizardschoolnft.com">back to homepage</a></div>
					<div className="logo_container d-flex justify-content-center mt-3">
						<StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
					</div>
					<div className="logo_container d-flex justify-content-center mt-3">
						<p className="header shadow">WIZARDS OF OS</p>
					</div>
					<div className="page_container body_slider">
						<div className="container-fluid">
							<div className="row align-items-center">
								
								<div className="col-12 col-md-6 ps-3 ps-md-5">
									<s.SpacerSmall />
									<ResponsiveWrapper test>
										<s.Container
											style={{
												backgroundColor: "rgba(4, 5, 30, 0.8)",
												borderRadius: 24,
												boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.0)",
												textAlign: "center",
												padding: "20px"
											}}
										>
											<h1
												className="display-4"
												style={{
													fontWeight: "bold",
													color: "var(--accent-text)",
												}}
											>
												{ data.totalSupply == 0 ? "?": data.totalSupply } / {CONFIG.MAX_SUPPLY}
											</h1>
											<p
												className="h5 mb-0"
												style={{
													color: "var(--primary-text)",
												}}
											>
												<StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
													{truncate(CONFIG.CONTRACT_ADDRESS, 15)}
												</StyledLink>
											</p>
											<s.SpacerSmall />
											{Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
												<>
													<s.TextTitle
														style={{
															color: "var(--accent-text)",
														}}
													>
														The sale has ended.
													</s.TextTitle>
													<s.TextDescription
														style={{
															color: "var(--accent-text)",
														}}
													>
														You can still find {CONFIG.NFT_NAME} on
													</s.TextDescription>
													<s.SpacerSmall />
													<StyledLink
														target={"_blank"}
														href={CONFIG.MARKETPLACE_LINK}
													>
														{CONFIG.MARKETPLACE}
													</StyledLink>
												</>
											) : (
												<>
													<s.TextTitle
														style={{
															color: "var(--accent-text)",
														}}
													>
														Free to mint
													</s.TextTitle>
													<s.SpacerXSmall />
													<p
														className="mb-1 mt-1"
														style={{
															color: "var(--accent-text)",
														}}
													>
														
													</p>
													{blockchain.account === "" ||
													blockchain.smartContract === null ? (
														<s.Container ai={"center"} jc={"center"}>
															<s.TextDescription
																style={{
																	color: "var(--accent-text)",
																}}
															>
																 Connect a wallet to update the supply
															</s.TextDescription>
															<s.SpacerSmall />
															<button
																style={{borderWidth: 1, borderColor: "white", borderStyle: "solid"}}
																className="btn text-white fw600 px-4"
																onClick={(e) => {
																	e.preventDefault();
																	dispatch(connect());
																	getData();
																}}
															>
																CONNECT
															</button>
															{blockchain.errorMsg !== "" ? (
																<>
																	<s.SpacerSmall />
																	<s.TextDescription
																		style={{
																			color: "var(--accent-text)",
																		}}
																	>
																		{blockchain.errorMsg}
																	</s.TextDescription>
																</>
															) : null}
														</s.Container>
													) : (
														<>
															<s.TextDescription
																style={{
																	color: "var(--secondary)",
																	fontSize: 24,
																}}
															>
																{feedback}
															</s.TextDescription>
															<div className="py-2" />
															<div className="d-flex justify-content-center">
																<StyledRoundButton
																	style={{ lineHeight: 0.4 }}
																	disabled={claimingNft ? 1 : 0}
																	onClick={(e) => {
																		e.preventDefault();
																		decrementMintAmount();
																	}}
																>
																	-
																</StyledRoundButton>
																<s.SpacerMedium />
																<s.TextDescription
																	style={{
																		color: "var(--accent-text)",
																	}}
																>
																	{mintAmount}
																</s.TextDescription>
																<s.SpacerMedium />
																<StyledRoundButton
																	disabled={claimingNft ? 1 : 0}
																	onClick={(e) => {
																		e.preventDefault();
																		incrementMintAmount();
																	}}
																>
																	+
																</StyledRoundButton>
															</div>
															<s.SpacerSmall />
															<s.Container
																ai={"center"}
																jc={"center"}
																fd={"row"}
															>
																<button
																style={{borderWidth: 1, borderColor: "white", borderStyle: "solid"}}
																	className="btn text-white fw600 px-4"
																	disabled={claimingNft ? 1 : 0}
																	onClick={(e) => {
																		e.preventDefault();
																		claimNFTs();
																		getData();
																	}}
																>
																	{claimingNft ? "BUSY" : "BUY"}
																</button>
															</s.Container>
														</>
													)}
												</>
											)}
											<s.SpacerMedium />
										</s.Container>
										<s.SpacerLarge />
									</ResponsiveWrapper>
									<s.SpacerMedium />
								</div>
							</div>
						</div>
					</div>

					{/* <div className="bottom_">
						<p
							style={{
								color: "var(--primary-text)",
							}}
						>
							Please make sure you are connected to the right network (
							{CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please
							note: Once you make the purchase, you cannot undo this action.
						</p>
						<s.SpacerSmall />
						<p
							style={{
								color: "var(--primary-text)",
							}}
						>
							We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract
							to successfully mint your NFT. We recommend that you don't lower
							the gas limit.
						</p>
					</div> */}
				</s.ContainerUpp>
				<div id="faq" className="faq_container">
					<div className="page_container">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<h1 className="faqheader text-center">FAQ</h1>

									<div className="team_card_container">
										{faqData.map((prev, i) => {
											return (
												<div key={i}>
													<Accordion t={prev.t} p={prev.p} />
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</s.Screen>
		</>
	);
}

export default App;
