export const faqData = [
	{
		t: "WHICH WALLET CAN I CONNECT TO MINT A WIZARDS OF OS NFT?",
		p: (
			<>
				You can connect a MetaMask wallet only. If you don’t have one, you can
				create one here:{" "}
				<a target="blank" className="text-white" href="https://metamask.io/">
					https://metamask.io
				</a>
			</>
		),
	},
	{
		t: "HOW DO I CONNECT MY WALLET?",
		p: (
			<>
				DESKTOP: Click on the "Connect" button above. After connecting, make sure you are on the Ethereum network.
				<br></br><br></br>
				METAMASK APP: Please follow the instructions in this video: {" "}
				<a style={{fontWeight: "bold"}} target="blank" className="text-white" href="https://www.youtube.com/watch?v=TYebhWeBhMw">
					HERE
				</a>
				
			</>
		),
	},
	{
		t: "WHAT IS MINTING?",
		p: (
			<>
				Minting a WIZARDS OF OS means that you are buying a digital art which
				becomes part of the Ethereum blockchain. Your digital artwork is
				represented as an NFT so it can be traded in the market and digitally
				tracked as it is sold or collected again in the future.
			</>
		),
	},
	{
		t: "HOW TO MINT?",
		p: (
			<>
Public Sale: You can mint at 12TH March 22:00 PM UTC.
Public Price is 0.02 ETH + Gas
			</>
		),
	},
	{
		t: "WHAT SHOULD I DO AFTER MINTING MY WIZARDS OF OS?",
		p: (
			<>
				WIZARDS OF OS NFT's can be used as a PFP, resold, or held in order to unlock future benefits that are our outlined in our Roadmap. Such as maybe the Second Collection, The Pets are coming and Staking rewarding System and a P2E game. But be excited we will build this with you!
			</>
		),
	},
	{
		t: "ARE THERE ROYALTY FEES?",
		p: (
			<>
				Yes, our royalty fee is 5%. These fees are used for marketing,
				promotion, staff development, and operational costs to ensure we are
				continually growing and expanding the WIZARDS OF OS community.
			</>
		),
	},
];
